<script>
import { layoutComputed } from '@/state/helpers'
import Horizontal from './horizontal_noti'

export default {
  components: { Horizontal },
  data() {
    return {}
  },
  computed: {
    ...layoutComputed,
  },
  methods: {},
}
</script>

<template>
  <div>
    <horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </horizontal>
  </div>
</template>

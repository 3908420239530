<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import Layout from '@/views/layouts/main_noti'
import i18n from '@/i18n'
import moment from 'moment'
import simplebar from 'simplebar-vue'
import service from '@/server/http_service'

export default {
  page: {
    title: i18n.t('top_topbar_menu.noti.noti_all'),
    meta: [{ name: 'description' }],
  },
  components: { Layout, simplebar },
  data() {
    var lang = 'th'
    if (localStorage.getItem('account_lang')) {
      lang = localStorage.getItem('account_lang')
    } else {
      localStorage.setItem('account_lang', 'th')
    }
    moment.locale(lang)
 
    var user = JSON.parse(localStorage.getItem('users'))
    var lang_obj = i18n.t('top_topbar_menu.noti')
    return {
      lang : lang_obj,
      current_language: lang,
      profile_name: user[0].name,
      personal_data: user[0],
      noti_search_order : '0',
      noti_search_input : '',
      noti_row_data : [],
      noti_row_data_temp : [],
    }
  },
  watch: {},
  mounted() {
    this.getAllNotification()
  },
  methods: {
    search_noti() {
      var that = this
      clearTimeout(that.debounce)
      this.debounce = setTimeout(function() {
        that.search_noti_cal()
      }, 500)
    },
    search_noti_cal() {
      let order = this.noti_search_order
      let input = this.noti_search_input
      if(this.noti_row_data.length > 0 ) {
        let search_data = this.noti_row_data
        let data_return = []

        for (let index = 0; index < search_data.length; index++) {
          let row = search_data[index]
          if(order != '0' && input != '') {
            if(row.noti_status == order) {
              if(row.noti_by_name.search(input) != -1) {
                data_return.push(row)
                weight_name++
              } else {
                if(row.noti_plan_id.search(input) != -1) {
                  data_return.push(row)
                }
              }
              
            } 
          } else if (order != '0') {
            if(row.noti_status == order) {
              data_return.push(row)
            }
          } else if (input != '') {
            if(row.noti_by_name.search(input) != -1) {
              data_return.push(row)
            } else {
              if(row.noti_plan_id.search(input) != -1) {
                data_return.push(row)
              }
            }
          } else {
            data_return = this.noti_row_data
          }
        }
        this.noti_row_data_temp = data_return

      }
    },
    getNotiStatus(status_id) {
      let status_name = ''
      if (status_id == '1') {
        status_name = this.lang.job_offer
      } else if (status_id == '2') {
        status_name = this.lang.new_offer
      } else if (status_id == '3') {
        status_name = this.lang.have_offer
      } else if (status_id == '4') {
        status_name = this.lang.answer
      } else if (status_id == '5') {
        status_name = this.lang.in_progress
      } else if (status_id == '6') {
        status_name = this.lang.succeed
      } else if (status_id == '7') {
        status_name = this.lang.cancel
      } else if (status_id == '8') {
        status_name = this.lang.expire
      } else {
        status_name = this.lang.noti
      }
      return status_name
    },
    getNotiStatusColor(status_id) {
      let status_color = ''
      if (status_id == '1') {
        status_color = 'status_1'
      } else if (status_id == '2') {
        status_color = 'status_1'
      } else if (status_id == '3') {
        status_color = 'status_1'
      } else if (status_id == '4') {
        status_color = 'status_1'
      } else if (status_id == '5') {
        status_color = 'status_1'
      } else if (status_id == '6') {
        status_color = 'status_2'
      } else if (status_id == '7') {
        status_color = 'status_3'
      } else if (status_id == '8') {
        status_color = 'status_8'
      } else {
        status_color = 'status_1'
      }
      return status_color
    },
    // eslint-disable-next-line no-unused-vars
    getAllNotification() {
      var that = this
      service
        .postService('getAllNotificationById', {
          id_member: that.personal_data.id_member,
        })
        .then((rp) => {
          // console.log(rp)
          if(rp.data != undefined) {
            this.createNotiRow(rp.data)
          }
        })
    },
    createNotiRow(all_noti_data) {
      let noti_data = all_noti_data
      // console.log(noti_data)
      this.noti_bell_data = []
      let data_array = []
      for (let index = 0; index < noti_data.length; index++) {
        let row = noti_data[index]

        if (row != '' && row != null && row != undefined) {
          row.noti_data = JSON.parse(row.noti_data)
          // แปลงรูปภาพ
          var pic_obj = []
          var status_pic_data = []
          var status_pic_flg = false
          for (let index2 = 0; index2 < row.noti_pic_data.length; index2++) {
            let data_row = row.noti_pic_data[index2]
            let element = data_row
            if (element.title == 'profile') {
              var path = element.blob
              pic_obj.push(path)
              status_pic_flg = true
            }
          }
          status_pic_data = pic_obj

          // เช็ต สถานะ noti
          var status_id = row.noti_data.push_status
          var status_class_name = this.getNotiStatusColor(status_id)
          var status_name = this.getNotiStatus(status_id)

          // สร้างข้อความ noti
          var by_name = row.noti_push_by.mover_name
          var plan_id = row.noti_data.push_detail.id_order_tracking
        
          let data = {
            noti_status: status_id,
            noti_status_class: status_class_name,
            noti_status_name: status_name,
            noti_pic_data: status_pic_data[0],
            noti_pic_flag: status_pic_flg,
            noti_plan_id: plan_id,
            noti_by_name: by_name,
            noti_time_fromnow : moment(row.noti_action_time).fromNow(),
          }
          data_array.push(data)
        }
      }

      this.noti_row_data = data_array
      this.search_noti_cal()
    },
  },
}
</script>

<template>
  <Layout>
    <div class="noti-container">
      <b-row>
        <b-col lg="6">
          <span class="noti_tile">
            {{ lang.noti }}
          </span>
        </b-col>
        <b-col lg="2">
          <select v-model="noti_search_order" name="noti_search_order" class="form-control" @change="search_noti()">
            <option value="0" selected>{{ lang.search_order_by.status_0 }}</option>
            <option value="1">{{ lang.search_order_by.status_1 }}</option>
            <option value="2">{{ lang.search_order_by.status_2 }}</option>
            <option value="3">{{ lang.search_order_by.status_3 }}</option>
            <option value="4">{{ lang.search_order_by.status_4 }}</option>
            <option value="5">{{ lang.search_order_by.status_5 }}</option>
            <option value="6">{{ lang.search_order_by.status_6 }}</option>
            <option value="7">{{ lang.search_order_by.status_7 }}</option>
            <option value="8">{{ lang.search_order_by.status_8 }}</option>
          </select>
        </b-col>
        <b-col lg="4" class="col-margin-bot-1">
          <div class="input-group prepend">
            <b-form-input id="noti_search_input" v-model="noti_search_input" type="text" class="form-control custom-input-prepend" :placeholder="lang.search_placeholder" @keyup="search_noti()" />
            <div class="input-group-prepend custom-input-group-prepend" @click="search_noti()">
              <span class="input-group-text custom-input-group-prepend-text search"><i class="ri-search-line" /></span>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <simplebar class="noti-main-display" style="padding: 0px">
          <transition-group name="bounce" class="custom-transition" enter-active-class="bounceInLeft" leave-active-class="bounceOutRight" tag="div">
            <b-col v-for="(option, index) in noti_row_data_temp" :key="index" lg="12" class="noti-col">
              <div class="d-flex align-items-center noti-row-data font-size-16">
                <div class="flex-fill">
                  <b-row>
                    <b-col lg="3">
                      <span :class="option.noti_status_class">[{{ option.noti_status_name }}]</span>
                    </b-col>
                    <b-col lg="4">
                      {{ lang.plan }} {{ option.noti_plan_id }}
                    </b-col>
                    <b-col lg="4">
                      <img v-if="option.noti_pic_flag" :src="option.noti_pic_data" class="mr-3 rounded-circle avatar-xs" alt="user-pic" />
                      <img v-else src="@/assets/images/profile/Group 3361.png" class="mr-3 rounded-circle avatar-xs" alt="user-pic" />
                      {{ option.noti_by_name }}
                    </b-col>
                    <b-col lg="1" class="d-flex justify-content-end">
                      <i class="mdi mdi-clock-outline" style="margin-right: 10px;" />
                      {{ option.noti_time_fromnow }}
                    </b-col>
                  </b-row>
                </div>
              </div>
            </b-col>
          </transition-group>
        </simplebar>
      </b-row>
    </div>
  </Layout>
</template>
<style>
.noti-container {
  padding: 40px;
}
.noti_tile {
  font-size: 32px;
  font-weight: 500;
}
.noti-main-display {
  max-height: 70.5vh;
  min-height: 70.5vh;
  width: 100%;
  /* margin: 12px; */
}
.noti-col {
  /* transition: all 1s; */
  display: inline-block;
  margin: 0 0 0.5rem 0;
}
.noti-row-data {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #b0bac4;
  border-radius: 5px;
  opacity: 1;
  max-height: 70px;
  min-height: 70px;
  padding: 1rem;
}
.status_1 {
  color: #03aeef;
}
.status_2 {
  color: #33c38f;
}
.status_3 {
  color: #ff5d5d;
}
.status_4 {
  color: #faab00;
}
</style>
